@use "../../variables" as v;

.home-contact .info-item {
  width: 50%;
  float: left;
}
.home-contact .info-item span:before {
  font-size: 30px;
  color: v.$color2;
}
.home-contact .info-item h4 {
  color: v.$color1;
}
.home-contact .home-map {
  height: 400px;
  width: 100%;
}
.contact .contact-map {
  height: 600px;
  width: 100%;
  position: relative;
}
.contact-item {
  list-style: none;
  border-bottom: 1px solid v.$color12;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.contact-item:last-child {
  border: none;
  margin: 0px;
  padding: 0px;
}
.contact-item .icon {
  display: inline-block;
  float: left;
  color: v.$color2;
  margin-right: 15px;
}
.contact-item .icon:before {
  font-size: 30px;
}
.contact-item .content {
  display: table;
}
.contact-item .content h4 {
  font-size: 18px;
  color: v.$color1;
}
.contact-item .content p {
  margin: 0px;
}
.contact-form .contact-input:nth-child(1) {
  width: 48%;
  float: left;
}
.contact-form .contact-input:nth-child(2) {
  width: 48%;
  float: right;
}
.contact-form .contact-input {
  width: 100%;
  margin-bottom: 10px;
  color: v.$color14;
  border: none;
  border-bottom: 1px solid v.$color12;
  padding: 15px;
  outline: none;
}
.contact-form .contact-input:focus {
  border-bottom: 1px solid v.$color2;
  color: v.$color2;
}
.contact-form .contact-button {
  background-color: v.$color1;
  outline: none;
  font-weight: 700;
  font-size: 16px;
  padding: 17px 30px;
  margin-top: 30px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.contact-form .contact-button:hover {
  background-color: v.$color2;
}
.map-area {
  height: 465px;
  background: v.$color7;
  box-shadow: v.$bs-color2 0px 5px 15px;
}
.map-area iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.page-header {
  background-image: url(/assets/images/background/page-header-bg.jpg);
  background-size: cover;
  background-position: center;
  margin: 0px;
  padding: 0px;
  border: none;
  font-family: fa-style-family;
}
.page-header .overlay {
  padding: 70px 0px;
  background-color: v.$op-color2;
  text-align: center;
}
