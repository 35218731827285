$color1: #6c5ebf; 
$color2: #C4B0FF; 
$color3: #212121;
$color4: #b1b1b1;
$color5: #ebebeb;
$color6: #db4437;
$color7: #ffffff;
$color8: #4942E4;
$color9: #23b6ea;
$color10: #e94c89;
$color11: #cb1f27;
$color12: #f0f0f0;
$color13: #f8f8f8;
$color14: #707070;
$color15: #C4B0FF;
$color16: #888888;
$color17: #fafafa;
$color18: #22313f;
$color19: #add546;
$color20: #6cabb8;
$color21: #0fbaf4;
$color22: #92278f;

/* Footer */
$color23: #e8e8e8;
$color24: #8696FE;
$color25: #8175c8;
$color26: #bfb4ff;
$color27: #fafafa;
$color28: #eeefee;
$color29: #C4B0FF;

/* Forms */
$color30: #1a1a1a;



/* Opacity */
$op-color1: rgba(0, 0, 0, 0.7);
$op-color2: rgba(0, 0, 0, 0.5);
$op-color3: rgba(196, 176, 255, 0.8);

/* Box-Shadow */
$bs-color1: rgba(0, 0, 0, 0.1);
$bs-color2: rgba(0, 0, 0, 0.35);



/* 
    BOOTSTRAP 
    $primary                    : $color1;
    $secondary                  : $color2;
    $font-family-sans-serif     : "Roboto";
    $navbar-light-color         : $color1;
    $navbar-light-hover-color   : $color2;
    $navbar-light-active-color  : $color2;
    $nav-link-font-weight       : 600;
    $border-radius              : 0;
    $form-feedback-valid-color  : $color1;
    $nav-tabs-link-active-color : $color2;
*/
