@use "../../variables" as v;

.achievements {
  background-image: url(/assets/images/background/achievements-bg.jpg);
  background-size: cover;
  background-position: center;
}
.achievements .overlay {
  background-color: v.$op-color1;
}
.achievements .achievement-item {
  text-align: center;
}
.achievements .achievement-item .icon {
  display: block;
}
.achievements .achievement-item .icon:before {
  margin: 0px;
  color: v.$color2;
  font-size: 50px;
}
.achievements .achievement-item span {
  color: v.$color7;
  font-size: 48px;
  font-weight: 700;
}
.achievements .achievement-item p {
  color: v.$color7;
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
}
