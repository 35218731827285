@use "../../variables" as v;

.blog {
  padding: 120px 0px 90px;
}
.blog .blog-item {
  background-color: v.$color7;
  box-shadow: 0px 0px 10px 2px v.$bs-color1;
  margin-bottom: 30px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.blog .blog-item:hover {
  -webkit-box-shadow: 0 10px 30px v.$bs-color1;
  box-shadow: 0 10px 30px v.$bs-color1;
}
.blog .blog-item .blog-image {
  overflow: hidden;
}
.blog .blog-item img {
  width: 100%;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.blog .blog-item:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.blog .blog-item .blog-content {
  padding: 15px;
}
.blog .blog-item .blog-content h4 a {
  text-decoration: none;
  color: v.$color3;
}
.blog .blog-item .blog-content h4 a:hover {
  color: v.$color2;
}
.blog .blog-item .blog-content p {
  margin-bottom: 10px;
  line-height: 30px;
}
.blog .blog-item ul {
  margin: 0px;
  border-top: 1px solid v.$color12;
  padding: 9px 15px;
}
.blog .blog-item ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
  color: v.$color14;
}
.blog .blog-item ul li a {
  text-decoration: none;
  font-size: 15px;
  color: v.$color14;
}
.blog .blog-item ul li .icon {
  margin-right: 5px;
  color: v.$color14;
}
.blog .blog-item ul li .icon:before {
  font-size: 18px;
}
.blog .blog-button {
  text-align: center;
}
.blog-two .blog-item {
  overflow: hidden;
}
.blog-two .blog-item .blog-content {
  padding: 30px;
}
.blog-two .blog-item-wide {
  margin-bottom: 30px;
}
.blog-two .blog-item-wide .blog-image {
  float: left;
}
.blog-two .blog-item-wide .blog-content {
  display: table;
}
.blog-two .blog-item-wide .blog-content p {
  line-height: 24px;
}
.blog-two .blog-item h4 {
  font-size: 20px;
}
.blog-two .blog-item ul {
  border: none;
  padding: 9px 0px;
}
.blog-two .blog-item ul li:first-child,
.blog-two .blog-item ul li:first-child a {
  color: v.$color1;
}
.blog-two .blog-item ul li:last-child,
.blog-two .blog-item ul li:last-child a {
  color: v.$color2;
}
.blog-two .blog-item a,
.blog-two .blog-item a:hover {
  text-decoration: none;
  color: v.$color2;
}
.blog-three {
  background-image: url(/assets/images/background/blog-bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 0px;
}
.blog-three .overlay {
  background-color: v.$op-color1;
}

.blog .blog-item-classes .blog-content {
  padding: 30px;
}
.blog .blog-item-classes h4 {
  margin-bottom: 5px;
}
.blog .blog-item-classes ul {
  border: none;
  padding: 0px;
  margin-bottom: 10px;
}
.blog .blog-item-classes .blog-content p {
  margin-bottom: 15px;
}
.post-meta li {
  list-style: none;
  display: inline-block;
  color: v.$color14;
  margin-right: 20px;
}
.post-meta li a {
  text-decoration: none;
  color: v.$color14;
}
.post-meta li .icon {
  margin-right: 5px;
}
.post-meta li .icon:before {
  font-size: 16px;
}
.single-post .post-content {
  padding: 30px 30px 0px;
  -webkit-box-shadow: 0px 0px 10px 2px v.$bs-color1;
  box-shadow: 0px 0px 10px 2px v.$bs-color1;
  margin-bottom: 50px;
}
.single-post .post-content h3 {
  font-size: 30px;
  color: v.$color3;
  margin-bottom: 10px;
}
.single-post .post-content blockquote {
  font-style: normal;
  color: v.$color3;
  font-weight: 700;
  font-size: 14px;
  border: none;
  padding: 0px 0px 0px 40px;
}
.single-post .post-content blockquote:after {
  left: 0px;
  top: 30%;
  color: v.$color2;
  font-size: 30px;
}
.single-post .post-content blockquote:before {
  content: "\f10e" !important;
  position: absolute;
  right: 50px;
  bottom: 0px;
  font: normal normal normal 30px/1 FontAwesome;
  color: v.$color2;
}
.single-post .post-content p:last-child {
  margin: 0px;
}
.single-post .content-bottom {
  border-top: 1px solid v.$color12;
  padding: 12px 0px;
  overflow: hidden;
}
.single-post .content-bottom ul {
  margin: 0px;
}
.single-post .content-bottom ul li {
  list-style: none;
  display: inline-block;
}
.single-post .content-bottom li:first-child {
  margin-right: 5px;
  font-style: italic;
  font-weight: 700;
  font-size: 18px;
  color: v.$color2;
}
.single-post .content-bottom li span {
  margin-right: 5px;
}
.single-post .content-bottom li span:before {
  color: v.$color14;
  font-size: 18px;
}
.single-post .post-tags {
  float: left;
}
.single-post .post-tags li a {
  font-style: normal;
  font-size: 16px;
  color: v.$color14;
  text-decoration: none;
}
.single-post .post-share {
  float: right;
}
.single-post .post-share li {
  margin-right: 10px;
}
.single-post .post-share li:last-child {
  margin: 0px;
}
.single-post .post-share li a {
  font-size: 16px;
  color: v.$color8;
}
.single-post .post-share li:nth-child(3) a {
  color: v.$color6;
}
.single-post .post-share li:nth-child(4) a {
  color: v.$color10;
}
.single-post .post-share li:nth-child(5) a {
  color: v.$color9;
}
.single-post .post-share li:nth-child(6) a {
  color: v.$color11;
}
.single-post .post-comments {
  margin-bottom: 20px;
}
.single-post .post-comments h3 {
  font-size: 24px;
  color: v.$color1;
  margin-bottom: 20px;
}
.single-post .post-comments ul {
  margin: 0px;
}
.single-post .post-comments li {
  list-style: none;
  margin-bottom: 20px;
  border-bottom: 1px solid v.$color12;
}
.single-post .post-comments li .the-comment {
  padding-bottom: 20px;
}
.single-post .post-comments li:last-child {
  margin: 0px;
  padding: 0px;
  border: none;
}
.single-post .post-comments li ul {
  margin-left: 90px;
}
.single-post .post-comments .comment-image {
  overflow: hidden;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  float: left;
  margin-right: 20px;
}
.single-post .post-comments .comment-content {
  display: table;
}
.single-post .post-comments .comment-content h4 {
  float: left;
  font-size: 18px;
  color: v.$color3;
  margin-right: 5px;
}
.single-post .post-comments .comment-content .comm-reply {
  float: right;
}
.single-post .post-comments .comment-content .comm-reply i {
  color: v.$color15;
  font-weight: 700;
}
.single-post .post-comments .comment-content .comm-reply i:before {
  font-size: 15px;
}
.single-post .post-comments .comment-content .comm-reply a {
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  color: v.$color14;
}
.single-post .post-comments .comment-content span {
  color: v.$color14;
}
.single-post .post-comments .comment-content p {
  margin-top: 5px;
  margin-bottom: 0px;
}
.single-post .reply-form h3 {
  font-size: 24px;
  color: v.$color1;
  margin-bottom: 30px;
}
.single-post .reply-form .com-input {
  width: 100%;
  border: 1px solid v.$color12;
  padding: 12px 15px;
  outline: none;
  margin-bottom: 30px;
}
.single-post .reply-form button {
  outline: none;
  padding: 17px 26px;
}
