@use "../../variables" as v;

.page-header {
  background-image: url(/assets/images/background/page-header-bg.jpg);
  background-size: cover;
  background-position: center;
  margin: 0px;
  padding: 0px;
  border: none;
}
.page-header .overlay {
  padding: 158px 0px;
  background-color: v.$op-color2;
  text-align: center;
}
.page-header h3 {
  font-size: 36px;
  color: v.$color7;
}
.page-header ul {
  margin: 0px;
}
.page-header ul li {
  list-style: none;
  display: inline-block;
  color: v.$color7;
  font-size: 18px;
}
.page-header ul li a {
  font-style: italic;
  color: v.$color7;
  text-decoration: none;
  font-size: 18px;
}
.scroll-top {
  display: inline-block;
  height: 50px;
  width: 50px;
  background-color: v.$color1;
  color: v.$color7;
  font-size: 16px;
  text-align: center;
  line-height: 50px;
  position: fixed;
  top: 50%;
  right: 10%;
  text-decoration: none;
  z-index: 999;
  border-radius: 3px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}
.scroll-top:hover,
.scroll-top:focus {
  background-color: v.$color2;
}
.scroll-top.open {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.scroll-top:hover,
.scroll-top:focus {
  color: v.$color7;
}
