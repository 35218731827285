@use "../../variables" as v;

.services .service-item {
  text-align: center;
  background-color: v.$color7;
  padding: 30px 25px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 10px 2px v.$bs-color1;
  box-shadow: 0px 0px 10px 2px v.$bs-color1;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.services .service-item:hover {
  -webkit-box-shadow: 0 10px 30px v.$bs-color1;
  box-shadow: 0 10px 30px v.$bs-color1;
}
.services .service-item .icon:before {
  font-size: 60px;
  color: v.$color1;
}
.services .service-item h4 {
  color: v.$color3;
  margin-bottom: 15px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.services .service-item:hover h4 {
  color: v.$color1;
}
.services .service-item p {
  margin-bottom: 15px;
}
.services .service-item a {
  color: v.$color1;
  font-weight: 700;
  text-decoration: none;
}
.services .service-item a:hover {
  color: v.$color1;
}
.services-two > div > div > div:nth-child(1) .service-item .icon {
  color: v.$color19;
}
.services-two > div > div > div:nth-child(2) .service-item .icon {
  color: v.$color1;
}
.services-two > div > div > div:nth-child(3) .service-item .icon {
  color: v.$color20;
}
.services-two > div > div > div:nth-child(4) .service-item .icon {
  color: v.$color1;
}
.services-two > div > div > div:nth-child(5) .service-item .icon {
  color: v.$color21;
}
.services-two > div > div > div:nth-child(6) .service-item .icon {
  color: v.$color22;
}
