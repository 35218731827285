@use "../../variables" as v;

.error-page {
  text-align: center;
  font-family: fa-style-family;
}
.error-page .error-content {
  text-align: center;
}
.error-page .error-content h2 {
  font-style: normal;
  font-size: 200px;
  line-height: 200px;
  color: v.$color2;
}
.error-page .error-content h3 {
  font-size: 40px;
  color: v.$color1;
}
.error-page .error-content p {
  color: v.$color3;
  font-size: 18px;
  line-height: 30px;
}
.error-page .error-icon {
  text-align: center;
  line-height: 1;
}
.error-page .error-icon span:before {
  font-size: 370px;
  color:  v.$color1;
}
