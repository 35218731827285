@use "../../variables" as v;

input {
  line-height: normal;
}
button,
button[disabled]:hover,
button[disabled]:focus,
input[type="button"],
input[type="button"][disabled]:hover,
input[type="button"][disabled]:focus,
input[type="reset"],
input[type="reset"][disabled]:hover,
input[type="reset"][disabled]:focus,
input[type="submit"],
input[type="submit"][disabled]:hover,
input[type="submit"][disabled]:focus {
  background: v.$color30;
  border: 0;
  border-radius: 2px;
  color: v.$color7;
  font-family: "Lora", serif;
  font-weight: 700;
  letter-spacing: 0.046875em;
  line-height: 1;
  padding: 0.84375em 0.875em;
}
.button-default {
  display: inline-block;
  text-decoration: none;
  color: v.$color7;
  font-size: 16px;
  font-weight: 700;
  background-color: v.$color1;
  border-radius: 2px;
  padding: 11px 30px;
  transition: 0.25s ease-out;
}
.button-default:hover,
.button-default:focus {
  text-decoration: none;
  color: v.$color7;
  background-color: v.$color2;
}
.padding-120 {
  padding: 120px 0px;
}
.section-bg {
  background-color:v.$color13;
}
.section-header {
  text-align: center;
  max-width: 650px;
  margin: 0px auto 55px;
}
.section-header h3 {
  color:v.$color1;
  margin-bottom: 10px;
}
.section-header p {
  line-height: 30px;
  margin: 0px;
}
.section-header.bg h3 {
  color: v.$color7;
}
.section-header.bg p {
  color: v.$color7;
}
.social-default {
  overflow: hidden;
  margin: 0px;
}
.social-default li {
  list-style: none;
  display: inline-block;
  float: left;
  margin-right: 10px;
}
.social-default li:last-child {
  margin: 0px;
}
.social-default li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 14px;
  line-height: 3;
  background-color: v.$color8;
  color: v.$color7;
  border-radius: 100%;
}
.social-default li:nth-child(2) a {
  background-color: v.$color10;
}
.social-default li:nth-child(3) a {
  background-color: v.$color6;
}
.social-default li:nth-child(4) a {
  background-color: v.$color9;
}
.social-default li:nth-child(5) a {
  background-color: v.$color11;
}
